import React, { useEffect } from 'react'
import styled from 'styled-components'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Container from '../components/Container'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;

  .questions {
    max-width: 896px;
  }

  @media (max-width: 544px) {
    padding: 56px 0 0 0;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.unit.getRem(45)};
  text-align: center;
  margin: 0;
  padding: 0;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(26)};
  }
`

const Description = styled.div`
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.unit.getRem(21)};
  text-align: center;
  margin: 24px 0 80px 0;

  .chat-with-us-action {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(18)};
    margin: 24px 0 56px 0;
  }
`

const Question = styled.div`
  border-bottom: 1px solid #e5e7ec;
  margin-bottom: 48px;

  :nth-last-child(1) {
    margin-bottom: 0;
  }

  .title {
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 600;
    margin-bottom: 24px;

    @media (max-width: 544px) {
      font-size: ${props => props.theme.unit.getRem(18)};
    }
  }

  .content {
    color: ${props => props.theme.colors.gray};
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 400;
    margin-bottom: 48px;

    @media (max-width: 544px) {
      font-size: ${props => props.theme.unit.getRem(18)};
    }
  }
`

const FAQs = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.analytics.track('FAQsPage Loaded')
      window.fbq('track', 'FAQsPage Loaded')
    }
  }, [])

  return (
    <React.Fragment>
      <Navbar black />

      <Container>
        <Wrapper>
          <Title>Frequently asked questions</Title>
          <Description>
            Can’t find an answer?{' '}
            <span
              className="chat-with-us-action"
              onClick={() => {
                if (window && window.Intercom) window.Intercom('show')
              }}
            >
              Chat with us
            </span>
            .
          </Description>

          <div className="questions">
            <Question>
              <div className="title">
                What does ContactSmarter integrate with?
              </div>
              <div className="content">
                We have a Zapier integration available so you can integrate with
                all the apps which are available on Zapier. Also, we integrate
                with Gmail and Outlook for appointments.
              </div>
            </Question>

            <Question>
              <div className="title">
                What type of support is included with this Service?
              </div>
              <div className="content">
                To assist you, ContactSmarter provides the following support
                options – email, phone, and chat, during standard business hours
                – Monday through Friday, 10AM–5PM UTC.
              </div>
            </Question>

            <Question>
              <div className="title">How does the free trial work?</div>
              <div className="content">
                When you sign up for ContactSmarter as a new subscriber or a
                returning subscriber who has not yet had a free trial, you’ll
                have 7-days trial!
              </div>
            </Question>

            <Question>
              <div className="title">What’s included in the trial?</div>
              <div className="content">
                It will depend on the plan you choose, but basically allows you
                to set up your campaigns, users, round robins, bring leads into
                the system, download the mobile app... everything needed to
                understand how to use the system to make an informed decision
                about whether to continue with us.
              </div>
            </Question>

            <Question>
              <div className="title">
                What happens at the end of my free trial?
              </div>
              <div className="content">
                If you do not cancel before the end of your free trial period,
                your account will automatically be charged the monthly
                subscription fee depending on which subscription you opted into.
              </div>
            </Question>

            <Question>
              <div className="title">
                Are there any limits on messages or overage fees?
              </div>
              <div className="content">
                No, ContactSmarter offers you unlimited Emails, SMS and
                Voicemails. You only pay the monthly fee for the plan you
                choose.
              </div>
            </Question>

            <Question>
              <div className="title">Can I change my plan later?</div>
              <div className="content">
                Yes, you can change your plan at any time. You can upgrade or
                downgrade to a different plan at any time. If you change your
                plan in the middle of the billing cycle, your monthly charge
                will be pro-rated on the time you have left on your current
                plan.
              </div>
            </Question>

            <Question>
              <div className="title">Is my data safe?</div>
              <div className="content">
                We take data security seriously at ContactSmarter. All data is
                stored in highly secure data centers, which meets dozens of
                compliance programs.
              </div>
            </Question>

            <Question>
              <div className="title">Can I cancel my account at any time?</div>
              <div className="content">
                Yes, if you ever decide that ContactSmarter isn’t the best
                automated leads follow-up system for your business, simply reach
                out our support team so they can help you with that.
              </div>
            </Question>
          </div>
        </Wrapper>
      </Container>

      <Footer center />
    </React.Fragment>
  )
}

export default FAQs
